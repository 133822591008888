import React, { useEffect } from 'react';
import { Link, graphql } from "gatsby"
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"
import Layout from "../components/layout"
import Seo from "../components/seo"
import axios from 'axios';

  
const handleSubmit = (event) => {
    event.preventDefault();
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute('6LebA7UZAAAAAE-T_OaU5GhtGxpyxYiNGGy-fzId', { action: 'submit' }).then(token => {
        document.getElementById('tokenid').value=token;
        var formData = new FormData(event.target);
        axios.post('https://koa.cloud/restapi/731c14be3fc264ab9eb7a6c045456276/wp-form', formData)
          .then((response) => {
            console.log(response.data);
            if(response.data.http_code=="200") {
                const formElement = document.querySelector('form');
                formElement.reset();
                const successMessage = document.createElement('div');
                successMessage.className = 'mt-2 alert alert-success';
                successMessage.innerHTML = 'Formulario recibido correctamente';
                formElement.appendChild(successMessage);
            } else {
                const formElement = document.querySelector('form');
                const successMessage = document.createElement('div');
                successMessage.className = 'mt-2 alert alert-danger';
                successMessage.innerHTML = 'Ha ocurrido un error, si el problema persiste envia un email.';
                formElement.appendChild(successMessage);
            }
          })
          .catch((error) => {
            console.log(error);
            const formElement = document.querySelector('form');
            const errorMessage = document.createElement('div');
            errorMessage.className = 'alert alert-danger';
            errorMessage.innerHTML = 'Ha ocurrido un error, si deseas contactar envíanos un email.';
            formElement.appendChild(errorMessage);
          });
      });
    });
  };

const Contacto = ({ data, location }) => {
    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
        const isScriptExist = document.getElementById(id);
    
        if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            script.onload = function () {
            if (callback) callback();
            };
            document.body.appendChild(script);
        }
    
        if (isScriptExist && callback) callback();
        }
    
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LebA7UZAAAAAE-T_OaU5GhtGxpyxYiNGGy-fzId`, function () {
        console.log("Script loaded!");
        });
    }, []);
    Contacto.handleSubmit = handleSubmit;
    const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} tigitle={siteTitle}>
    <Seo title="Contacto" />
    <article
      className="global-wrapper blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
    <h1 itemProp="headline">Contacto</h1>
        <section itemProp="articleBody">
            <form onSubmit={handleSubmit} method="Post">
                <input type="hidden" name="token" id="tokenid" value=""/>
                <input type="hidden" name='site' value={siteTitle} />
                <div className="form-group">
                    <label for="contacto-nombre">Nombre:</label>
                    <input type="text" name="nombre" className="form-control" id="contacto-nombre" aria-describedby="emailHelp" placeholder="Name" />
                </div>
                <br/>
                <div className="form-group">
                    <label for="contacto-email">Email:</label>
                    <input type="email" name="email" className="form-control" id="contacto-email" aria-describedby="emailHelp" placeholder="email@domain.com" />
                </div>
                <br/>
                <div class="form-group">
                    <label for="contacto-msj">Mensaje:</label>
                    <textarea name="comentarios" class="form-control" id="contacto-msj" rows="3"></textarea>
                </div>
                <br/>
                <div className="form-check">
                    <input name="tyc" type="checkbox" className="form-check-input" id="tyc" />
                    <label className="form-check-label" for="tyc">
                    <small id="emailHelp" className="form-text text-muted">He leído y acepto la <Link to="/aviso-legal/">política de privacidad</Link>.</small></label>
                </div>
                <br/>
                <button type="submit" className="btn btn-primary" data-callback="onSubmit">Enviar</button>
            </form>
        </section>
    </article>
    </Layout>
  )
}

export default Contacto

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
